import type { RsPathType } from "~/types/common";
import { createMemo, For, Show } from "solid-js";

import "./Pagination.css";
import { urlRs } from "~/utils/url";
import { A, useSearchParams } from "@solidjs/router";
import { ITEMS_PER_PAGE } from "~/utils/constants";
import type {
  ItemReference,
  ProgramRefWithLocation,
} from "~/types/drupal_jsonapi";

export type ProgramPaginationStore = {
  sort: "cp" | "ville";
  currentPage: number;
  slices: ProgramRefWithLocation[][];
  displayedPrograms: ProgramRefWithLocation[];
};

export type ItemReferencePaginationStore = {
  currentPage: number;
  slices: ItemReference[][];
  displayedItems: ItemReference[];
};

export function Pagination(props: {
  currentPage: number;
  totalPages: number;
  url: string;
  type?: RsPathType;
  scrollTo?: string;
}) {
  const pages = createMemo(() => {
    if (props.url) {
      const [currentParams] = useSearchParams();
      const url = new URL(props.url, import.meta.env.VITE_COG_FRONT_BASE_URL);
      const path = url.pathname;
      return Array.from(Array(props.totalPages).keys()).map((i) => {
        const searchParams = new URLSearchParams(url.searchParams);

        // If on the first page, remove the page query param
        if (i !== 0) {
          searchParams.set("page", i.toString());
        }

        currentParams.cog_a && searchParams.set("cog_a", currentParams.cog_a);

        const newPathname = searchParams.toString()
          ? `${path}?${searchParams.toString()}`
          : path;

        return {
          label: i + 1, // Drupal is 0-based, but human are not.
          isCurrent: i === props.currentPage,
          href: newPathname,
        };
      });
    }
  });

  return (
    <nav class="pagination" data-test="pager">
      <ul>
        <For each={pages()}>
          {(page, index) => (
            <>
              <li>
                <Show
                  when={!page.isCurrent}
                  fallback={
                    <span class="current" data-test="current">
                      {page.label}
                    </span>
                  }
                >
                  <A
                    noScroll
                    onClick={() => {
                      if (props.scrollTo) {
                        scrollTo(props.scrollTo);
                      }
                    }}
                    href={props.type ? urlRs(props.type, page.href) : page.href}
                    rel={
                      index() === props.currentPage - 1
                        ? "prev"
                        : index() === props.currentPage + 1
                          ? "next"
                          : undefined
                    }
                  >
                    {page.label}
                  </A>
                </Show>
              </li>
            </>
          )}
        </For>
      </ul>
    </nav>
  );
}

export function paginationSlices<T>(
  items: T[],
  opts?: { insertPromoCard?: boolean; itemsPerPage?: number },
): T[][] {
  if (items.length > 0) {
    const itemsPerPage = opts?.itemsPerPage || ITEMS_PER_PAGE;
    const itemsToSlice = [...items]; // Create a new array to avoid modifying the original
    const result: T[][] = [];

    for (let i = 0; i < Math.ceil(itemsToSlice.length / itemsPerPage); i++) {
      const slice = itemsToSlice.slice(
        i * itemsPerPage,
        i * itemsPerPage + itemsPerPage,
      );
      result.push(slice);
    }

    if (opts?.insertPromoCard) {
      return rebalanceArrays(result);
    }
    return result;
  } else {
    return [[]];
  }
}

function scrollTo(selector: string) {
  const target = document.querySelector(selector);
  if (target) {
    target.scrollIntoView({
      behavior: "smooth",
    });
  }
}

/**
 * Rebalances an array of arrays by moving excess elements from each array (except the last one)
 * to the beginning of the subsequent array when they exceed the specified maximum length.
 *
 * @template T - The type of elements contained in the arrays
 * @param {T[][]} arrays - The input array of arrays to rebalance
 * @param {number} [maxElements=5] - Maximum number of elements allowed in each array (except the last one)
 * @returns {T[][]} A new array of arrays with the elements rebalanced
 *
 * @example
 * // Rebalance number arrays with default max (5)
 * const input = [[1,2,3,4,5,6], [7,8,9,10,11,12], [13,14]];
 * const result = rebalanceArrays(input);
 * // result: [[1,2,3,4,5], [6,7,8,9,10,11], [12,13,14]]
 *
 * @example
 * // Rebalance with custom max elements
 * const result = rebalanceArrays(input, 4);
 * // result: [[1,2,3,4], [5,6,7,8,9,10,11], [12,13,14]]
 */
function rebalanceArrays<T>(arrays: T[][], maxElements: number = 5): T[][] {
  // Make a deep copy to avoid modifying the input
  const result: T[][] = arrays.map((arr) => [...arr]);

  for (let i = 0; i < result.length - 1; i++) {
    // While current array has more elements than maxElements
    while (result[i].length > maxElements) {
      // Remove the last element from current array
      const elementToMove = result[i].pop();

      // Add it to the beginning of next array if elementToMove is defined
      if (elementToMove !== undefined) {
        result[i + 1].unshift(elementToMove);
      }
    }
  }

  return result;
}
